<template>
    <div v-if="name === 'email'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="#A8A8A8" xmlns="http://www.w3.org/2000/svg">
            <path :class="{'red-fill': isError}" d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z" fill="#A8A8A8"/>
        </svg>
    </div>
    <div v-else-if="name === 'password'">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="#717171" xmlns="http://www.w3.org/2000/svg">
            <path :class="{'red-fill': isError}" d="M9.26316 3.36842C11.5874 3.36842 13.4737 5.25474 13.4737 7.57895C13.4737 8.12632 13.3642 8.64 13.1705 9.12L15.6295 11.5789C16.9011 10.5179 17.9032 9.14526 18.5179 7.57895C17.0611 3.88211 13.4653 1.26316 9.25474 1.26316C8.07579 1.26316 6.94737 1.47368 5.90316 1.85263L7.72211 3.67158C8.2021 3.47789 8.71579 3.36842 9.26316 3.36842ZM0.842105 1.06947L2.76211 2.98947L3.14947 3.37684C1.75158 4.46316 0.656842 5.91158 0 7.57895C1.45684 11.2758 5.05263 13.8947 9.26316 13.8947C10.5684 13.8947 11.8147 13.6421 12.9516 13.1874L13.3053 13.5411L15.7726 16L16.8421 14.9305L1.91158 0L0.842105 1.06947ZM5.49895 5.72632L6.80421 7.03158C6.7621 7.20842 6.73684 7.39368 6.73684 7.57895C6.73684 8.97684 7.86526 10.1053 9.26316 10.1053C9.44842 10.1053 9.63368 10.08 9.81052 10.0379L11.1158 11.3432C10.5516 11.6211 9.92842 11.7895 9.26316 11.7895C6.93895 11.7895 5.05263 9.90316 5.05263 7.57895C5.05263 6.91368 5.22105 6.29053 5.49895 5.72632ZM9.12842 5.06947L11.7811 7.72211L11.7979 7.58737C11.7979 6.18947 10.6695 5.06105 9.27158 5.06105L9.12842 5.06947Z" fill="#717171"/>
        </svg>
    </div>
    <div v-else-if="name='mail'">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.81818 0H18.1818C19.186 0 20 0.814028 20 1.81818V14.5455C20 15.5496 19.186 16.3636 18.1818 16.3636H1.81818C0.814028 16.3636 0 15.5496 0 14.5455V1.81818C0 0.814028 0.814028 0 1.81818 0ZM1.81818 6.01648V14.5455H18.1818V6.01686L9.99996 10.1078L1.81818 6.01648ZM1.81818 3.98365L10 8.07498L18.1818 3.98407V1.81818H1.81818V3.98365Z" fill="#A8A8A8"/>
        </svg>
    </div>
</template>
<script>
export default {
    props: ['name', 'isError']
};
</script>
<style lang="scss" scoped>
.red-fill{
    fill:#D74A41;
}
</style>